import React from 'react';
import Image from 'next/image';
import { SERVICES_DATA } from '../../constants/data';

const OurServices = () => {
  return (
    <section className="w-full px-6 sm:w-[90%] xl:max-w-[1440px] mx-auto mt-12 sm:mt-14 md:mt-20 lg:mt-24 lg:mb-32">
      <div className="sm:mb-16 mb-8 text-center">
        <h2 className="sm:w-[501px] mb-2 mx-auto font-semibold sm:font-bold text-2xl md:mb-10 lg:mb-16 sm:text-4xl lg:text-5xl lg:leading-[72.72px] text-primary sm:text-center relative">
          Our
          <span className="ml-2 text-gray-darker inline-block after:block after:absolute after:right-10 after:bottom-2 sm:after:-right-7 sm:after:bottom-4 after:-z-10 after:bg-primary after:w-[93.85px] after:h-1 sm:after:w-[221px] sm:after:h-2">
            Services
          </span>
        </h2>
        <h3 className="w-full sm:w-[80%] mt-4 font-medium sm:font-bold text-base sm:text-2xl mx-auto text-gray-darker mb-8 sm:mb-16 text-center">
          With Bitverx, your dream software product is within reach. Choose from
          our array of development services to give your business the strategic
          advantage it needs.
        </h3>
      </div>
      <div className="flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {SERVICES_DATA.map((service) => (
          <div
            key={service.id}
            className="bg-white shadow-lg rounded-lg p-6 pb-16 sm:p-9 sm:pb-20 flex flex-col gap-6 text-left max-w-full"
          >
            {/* Prioritize Service Image */}
            <div className="flex items-center">
              <Image
                src={service.img}
                alt={service.title}
                height={56}
                width={56}
              />
              <h4 className="ml-6 font-semibold text-lg md:text-xl">{service.title}</h4>
            </div>
            <p className="text-sm leading-6 sm:text-lg text-gray-dark">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurServices;