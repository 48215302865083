import APP_IMAGES from './appImages';
import { TECH_LOGOS, SERVICE_IMAGES, INDUSTRIES_IMG, TESTIMONAL_IMAGES } from './appImages';

// For Home page
export const HOME_SLIDES = [
  {
    id: 'm1',
    imgUrl: APP_IMAGES.courselImg,
    title: ' Leading companies trust us to develop software.',
    discription:
      'We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software development company that guarantees the successful delivery of your project.'
  },
  {
    id: 'm2',
    imgUrl: APP_IMAGES.courselImg,
    title: ' Leading companies trust us to develop software 2.',
    discription:
      'We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software development company that guarantees the successful delivery of your project.'
  },
  {
    id: 'm3',
    imgUrl: APP_IMAGES.courselImg,
    title: ' Leading companies trust us to develop software 3.',
    discription:
      'We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software development company that guarantees the successful delivery of your project.'
  },
  {
    id: 'm4',
    imgUrl: APP_IMAGES.courselImg,
    title: ' Leading companies trust us to develop software 4.',
    discription:
      'We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software development company that guarantees the successful delivery of your project.'
  }
];

// for Testimonals data
export const TESTIMONALS_DATA = [
  {
    id: 't1',
    profile: TESTIMONAL_IMAGES.sandra,
    name: 'Sandra Lloyd',
    position: '',
    description:
      '"Bitverx was a pleasure to work with. They always made themselves available and were always prompt and respectful in their communications. I found them to be very good at getting the work done and also very helpful and accommodating when there were unexpected issues. It would be my pleasure and honor to work with them again."'
  },
  {
    id: 't2',
    profile: TESTIMONAL_IMAGES.tarik,
    name: 'Tarik Menzies',
    position: '',
    description:
      `"Look no further. I have never been more confident in the quality of a company's work or quality. Excellent and unmatched."`
  },
  {
    id: 't3',
    profile: '',
    name: 'Nichole Merchant',
    position: '',
    description:
      '"Bitverx is a fantastic company that does impeccable work. Their customer service is second to none, and they always make time for me no matter how busy they are or what time of day. They truly appreciate that I’m such a perfectionist, and you would be hard-pressed to find a more patient team. Working with Bitverx feels like working with good friends—they put 100% effort and quality into their work."'
  },
  {
    id: 't4',
    profile: '',
    name: 'Neha Parikh',
    position: '',
    description:
      '"I feel extremely comfortable working with Bitverx. In fact, I feel that I will become too dependent on them for all web development tasks. They are extremely qualified in their domain—completing tasks in no time and suggesting improvements. They are also extremely professional—always keeping you updated on progress. I wonder how they manage to deliver everything in such a short time-span."'
  },
  {
    id: 't5',
    profile: '',
    name: 'Joshua Hicks',
    position: '',
    description:
      '"Bitverx has been a pleasure to work with. This was a large project which they took on and did well. There were complexities with the APIs not having the greatest documentation, but they came through with their own knowledge and trial and error. Would hire them again."'
  },
  {
    id: 't6',
    profile: TESTIMONAL_IMAGES.dan,
    name: 'Dan Neame',
    position: 'Senior Engineering Manager - Brandwatch',
    description:
      '"Bitverx helped us design, build, and launch our product after joining an existing fast-paced team. They immediately fit in very well, were communicative and delivery-focused, and are a highly competent company with a broad knowledge of technologies and practices. Would not hesitate to recommend."'
  },
];

// For Service page
export const PORTFOLIO_PRODUCT_DATA = [
  {
    id: 'p1',
    title: 'Web & Mobile App 1',
    subTitleL:
      'Improving the online web experience for International Hockey Federation (FIH)',
    description:
      'FIH was clear in their vision and expectations from the new website. To make the website digital companion for all things Hockey...',
    linkText: 'Checkout our Journey',
    image: APP_IMAGES.portFolioProductSliderImg
  },
  {
    id: 'p2',
    title: 'Web & Mobile App 2',
    subTitleL:
      'Improving the online web experience for International Hockey Federation (FIH)',
    description:
      'FIH was clear in their vision and expectations from the new website. To make the website digital companion for all things Hockey...',
    linkText: 'Checkout our Journey',
    image: APP_IMAGES.portFolioProductSliderImg
  },
  {
    id: 'p3',
    title: 'Web & Mobile App 3',
    subTitleL:
      'Improving the online web experience for International Hockey Federation (FIH)',
    description:
      'FIH was clear in their vision and expectations from the new website. To make the website digital companion for all things Hockey...',
    linkText: 'Checkout our Journey',
    image: APP_IMAGES.portFolioProductSliderImg
  }
];

// Services Data
export const SERVICES_DATA = [
  {
    id: 's1',
    title: 'UI/UX Design',
    description: `Our UI/UX designers create appealing user experiences for websites
            and mobile apps, working closely with you to meet your business
            goals.`,
    img: SERVICE_IMAGES.ux
  },
  {
    id: 's2',
    title: 'Web Development',
    description: `Whether it’s Single Page Applications, Progressive Web Apps, or
            intricate web applications, our full-stack developers deliver
            top-tier frontend and backend solutions.`,
    img: SERVICE_IMAGES.web
  },
  {
    id: 's3',
    title: 'Mobile Development',
    description: `Craft inventive apps for iOS and Android, independently or together,
            using cross-platform and hybrid development. Our developers are
            skilled in diverse SDKs and toolchains.`,
    img: SERVICE_IMAGES.mobile
  },
  {
    id: 's4',
    title: 'Ecommerce Development',
    description: `Starting or growing your online business? We have ecommerce experts
            for starting or scaling online stores, specializing in Shopify,
            WooCommerce, Magento and custom builds.`,
    img: SERVICE_IMAGES.ecommerce
  },
  {
    id: 's5',
    title: 'DevOps Services',
    description: `Optimize your software development and deployment processes with our
            DevOps services. We provide tailored tool and platform selection,
            streamlining operations for optimal productivity.`,
    img: SERVICE_IMAGES.devOps
  },
  {
    id: 's6',
    title: 'Blockchain Development',
    description: `Create user-centric enterprise apps that are innovative with smart
            integration capabilities and mobility solutions irrespective of the
            platform. We offer native, hybrid, and cross-platform development.`,
    img: SERVICE_IMAGES.blockchain
  }
];

// Industries Data
export const INDUSTRIES_DATA = [
  {
    id: 's1',
    name: 'Healthcare',
    description: `Enhance patient care with Bitverx’s secure healthcare solutions. Our platform streamlines interactions, supports personalized care plans, and protects sensitive data, offering your organization improved efficiency, stronger security, and better patient care.
                 `,
    description1: 'Ready to elevate your applications? Reach out to us today to get started.',
    imgURL: INDUSTRIES_IMG.instanConsultImg
  },
  {
    id: 's2',
    name: 'Saas',
    description: `We deliver tailored SaaS solutions that boost efficiency, enhance user engagement, and drive growth. Our custom software streamlines operations and automates workflows, helping your business stay competitive in a dynamic market.`,
    imgURL: INDUSTRIES_IMG.richReturnImg
  },
  {
    id: 's3',
    name: 'Education',
    description: `Our solutions streamline administrative tasks, boost student engagement, and enhance communication in education. By automating processes and using data insights, we improve efficiency and let educators focus on teaching. This helps your organization reduce workload, operate more effectively, and achieve better student outcomes.`,
    imgURL: INDUSTRIES_IMG.instatuitionImg
  },
  {
    id: 's4',
    name: 'Finance',
    description: `Our tailored finance solutions boost operational efficiency and improve customer engagement. By seamlessly integrating with your systems and providing data-driven insights, we enhance financial processes while ensuring regulatory compliance. Our robust and adaptable solutions are designed to streamline your operations and support your success in a dynamic market.`,
    imgURL: INDUSTRIES_IMG.fullCastImg
  },
  {
    id: 's5',
    name: 'E-Commerce',
    description: `We are expert in developing tailored E-commerce solutions that tackle retail challenges head-on. Our tools optimize inventory management, boost customer engagement, and automate advertising, all while using advanced analytics to drive growth and efficiency. Partner with us to streamline your retail operations and enhance your business success.`,
    imgURL: INDUSTRIES_IMG.santraImg
  },
  {
    id: 's6',
    name: 'Real Estate',
    description: `Transform your real estate operations with our advanced solutions. We provide data-driven insights and tools that enhance efficiency, streamline transactions, and improve client interactions. Our technology helps your organization make informed decisions and gain a competitive edge, boosting productivity and driving success.`,
    imgURL: INDUSTRIES_IMG.ropoImg
  },
  {
    id: 's7',
    name: 'Travel & Tourism',
    description: `We offer software solutions for the travel and tourism sector that streamline booking, enhance communication, and provide personalized experiences. Our tools use data insights and advanced technology to boost efficiency, meet customer needs, and drive success, covering all aspects from reservations to customer service.`,
    imgURL: INDUSTRIES_IMG.wtcTravelImg
  },
  {
    id: 's8',
    name: 'Insurance',
    description: `Our software solutions for the insurance industry streamline processes, enhance risk management, and personalize client interactions, boosting efficiency and ensuring compliance. This helps your organization adapt and grow in a dynamic environment, improving overall performance.`,
    imgURL: INDUSTRIES_IMG.aicImg
  },
  {
    id: 's9',
    name: 'On-Demand Services',
    description: `We provide advanced software solutions specifically designed to excel in the fast-paced world of on-demand services. Our technology enhances operational efficiency and flexibility, enabling businesses to effectively meet the evolving demands of the market.`,
    imgURL: INDUSTRIES_IMG.noYellingImg
  }
];

// Technologies Logos Data
export const FRONTEND_TECH = [
  {
    id: 'f1',
    name: 'HTML',
    logo: TECH_LOGOS.html
  },
  {
    id: 'f2',
    name: 'CSS',
    logo: TECH_LOGOS.css
  },
  {
    id: 'f3',
    name: 'JavaScript',
    logo: TECH_LOGOS.javascript
  },
  {
    id: 'f4',
    name: 'TypeScript',
    logo: TECH_LOGOS.typescript
  },
  {
    id: 'f5',
    name: 'ReactJS',
    logo: TECH_LOGOS.react
  },
  {
    id: 'f6',
    name: 'AngularJS',
    logo: TECH_LOGOS.angular
  },
  {
    id: 'f7',
    name: 'VueJS',
    logo: TECH_LOGOS.vue
  },

  {
    id: 'f8',
    name: 'NextJS',
    logo: TECH_LOGOS.nextjs
  },
  {
    id: 'f9',
    name: 'GatsBy',
    logo: TECH_LOGOS.gatsby
  },
  {
    id: 'f10',
    name: 'Material-UI',
    logo: TECH_LOGOS.materialUI
  },
  {
    id: 'f11',
    name: 'Ant-Design',
    logo: TECH_LOGOS.antDesign
  },
  {
    id: 'f12',
    name: 'Bootstrap',
    logo: TECH_LOGOS.bootstrap
  },
  {
    id: 'f13',
    name: 'Bulma',
    logo: TECH_LOGOS.bulma
  },

  {
    id: 'f14',
    name: 'TailwindCSS',
    logo: TECH_LOGOS.tailwindCSS
  },

  {
    id: 'f15',
    name: 'SASS',
    logo: TECH_LOGOS.sass
  }
];
export const BACKEND_TECH = [
  {
    id: 'b1',
    name: 'NodeJs',
    logo: TECH_LOGOS.nodejs
  },
  {
    id: 'b2',
    name: 'NestJs',
    logo: TECH_LOGOS.nestjs
  },
  {
    id: 'b3',
    name: 'Python',
    logo: TECH_LOGOS.python
  },
  {
    id: 'b4',
    name: 'DJango',
    logo: TECH_LOGOS.django
  },
  {
    id: 'b5',
    name: 'PHP',
    logo: TECH_LOGOS.php
  },

  {
    id: 'b6',
    name: 'REST API',
    logo: TECH_LOGOS.restApis
  },
  {
    id: 'b7',
    name: 'Laravel',
    logo: TECH_LOGOS.laravel
  }
];
export const DATABASE_TECH = [
  {
    id: 'd1',
    name: 'MongoDB',
    logo: TECH_LOGOS.mongo_db
  },
  {
    id: 'd2',
    name: 'Firebase',
    logo: TECH_LOGOS.firebase
  },
  {
    id: 'd3',
    name: 'MySQL',
    logo: TECH_LOGOS.my_sql
  },
  {
    id: 'd4',
    name: 'Microsoft Sql Server',
    logo: TECH_LOGOS.microsoft_sql_server
  },
  {
    id: 'd5',
    name: 'PostgreSQL',
    logo: TECH_LOGOS.el_sql
  },
  {
    id: 'd6',
    name: 'Supabase',
    logo: TECH_LOGOS.supabase,
    width: 800
  }
];
export const MOBILE_TECH = [
  {
    id: 'm1',
    name: 'Android',
    logo: TECH_LOGOS.android
  },
  {
    id: 'm2',
    name: 'iOS',
    logo: TECH_LOGOS.apple
  },
  {
    id: 'm3',
    name: 'Flutter',
    logo: TECH_LOGOS.flutter
  },
  {
    id: 'm4',
    name: 'Flutter Flow',
    logo: TECH_LOGOS.flutter_flow,
    width: 700
  },
  {
    id: 'm5',
    name: 'React Native',
    logo: TECH_LOGOS.react_native
  }
];

export const CMS_TECH = [
  {
    id: 'c1',
    name: 'WordPress',
    logo: TECH_LOGOS.word_press_logo
  },
  {
    id: 'c2',
    name: 'Strapi',
    logo: TECH_LOGOS.strapi_logo
  },
  {
    id: 'c3',
    name: 'Dato',
    logo: TECH_LOGOS.dato_logo
  },
  {
    id: 'c4',
    name: 'Shopify',
    logo: TECH_LOGOS.shopify,
    width: 200
  },
  {
    id: 'c5',
    name: 'ContentFul',
    logo: TECH_LOGOS.contentFul,
    width: 500
  },
];
export const UI_UX_TECH = [
  {
    id: 'u1',
    name: 'Figma',
    logo: TECH_LOGOS.figma_logo
  },
  {
    id: 'u2',
    name: 'Adobe Photoshop',
    logo: TECH_LOGOS.photoshop_logo
  },
  {
    id: 'u3',
    name: 'Adobe Illustrator',
    logo: TECH_LOGOS.illustrator_logo
  },
  {
    id: 'u4',
    name: 'Adobe XD',
    logo: TECH_LOGOS.xd_logo
  },
  {
    id: 'u5',
    name: 'Sketch',
    logo: TECH_LOGOS.sketch_logo
  },
  {
    id: 'u6',
    name: 'Zeplin',
    logo: TECH_LOGOS.zeplin
  }
];

export const CORE_VALUE_DATA = [
  {
    id: 'v1',
    image: APP_IMAGES.medal,
    title: 'Product Quality',
    description:
      'We entitle ourselves to provide the highest degree of quality in every solution, product, service and endeavor of our company. Excellence and maintaining high quality is our prime mission.'
  },
  {
    id: 'v2',
    image: APP_IMAGES.integration,
    title: 'Integrity',
    description:
      'We are loyal and committed, adhere to the organization’s rules and policies. We address conflict of interests peacefully, communicate openly, and welcome diversity. We compromise personal benefits for organization’s good and do what is expected of us even if it takes going an extra mile.'
  },
  {
    id: 'v3',
    image: APP_IMAGES.secretFile,
    title: 'Confidentiality',
    description:
      'We believe in respecting the confidential information entrusted by clients and candidates in accordance with the contractual agreements.'
  },
  {
    id: 'v4',
    image: APP_IMAGES.transparency,
    title: 'Transparency',
    description:
      'We are transparent about our business practice and dealings with all stakeholders. We make clear and open policies and make sure to put everything on the table for our client before a contract is signed.'
  },
  {
    id: 'v5',
    image: APP_IMAGES.timeManagement,
    title: 'Productivity',
    description:
      'Our custom software solutions are designed to streamline your workflows, automate repetitive tasks, and enhance overall efficiency, resulting in significant time and cost savings.'
  },
  {
    id: 'v6',
    image: APP_IMAGES.adaptation,
    title: 'Adaptability',
    description:
      'We understand that business needs evolve. Our agile development approach allows us to quickly adapt to changing market conditions, ensuring your software remains relevant and competitive.'
  },
  {
    id: 'v7',
    image: APP_IMAGES.responsibility,
    title: 'Responsibility',
    description:
      'We are committed to building long-term partnerships with our clients. Our dedication to quality, security, and client satisfaction is reflected in every software solution we deliver.'
  }
];
